<template>
  <div class="container wrapper">
    <div class="content">
      <BaseLoad v-if="loading" />
      <div class="con" v-else>
        <!-- lesson详情 -->
        <div class="flex item">
          <div class="item-l">
            <img :src="preSrc + info.cover" alt="" />
          </div>
          <div class="item-r">
            <h3 class="ellipsis" :title="info.name">{{ info.name }}</h3>
            <p class="desc">{{ info.desc }}</p>

            <div class="flex favor-work">
              <CourseCancelFavor
                v-if="info.collect_status == 1"
                :cId="info.lesson_id"
                :cStatus="2"
                :cType="3"
                :fontSize="20"
                @favorCancel="handleFavorCanel"
              />
              <CourseFavor
                v-else
                :cId="info.lesson_id"
                :cStatus="1"
                :cType="3"
                :fontSize="20"
                @favorStatus="handleFavor"
              />
              <span class="slash">/</span>
              <WorkbenchCancel
                v-if="info.issue_status == 1"
                :cType="3"
                :cId="info.lesson_id"
                :cTitle="info.name"
                :cStatus="2"
                :fontSize="20"
                @workbenchCancel="workbenchCancel"
              />
              <Workbench
                v-else
                :cType="3"
                :cId="info.lesson_id"
                :cTitle="info.name"
                :cStatus="1"
                :fontSize="20"
                @handleWorkbench="handleWorkbench"
              />
            </div>
          </div>
        </div>
        <!-- 课件目录 -->
        <div class="course-title" v-if="info.content">
          <p class="line"></p>
          <div class="title">
            <img src="@/assets/img/course/level-title.png" alt="" />
            <span>课件详情</span>
          </div>
        </div>
        <div class="lesson-con" v-html="info.content"></div>
      </div>
    </div>
    <BaseCopyright />
  </div>
</template>
<script>
import BaseEmpty from "@/components/empty/BaseEmpty.vue";
import BaseLoad from "@/components/loading/BaseLoading.vue";
import ListLoad from "@/components/loading/ListLoading.vue";
export default {
  components: {
    BaseEmpty,
    BaseLoad,
    ListLoad,
  },
  data() {
    return {
      preSrc: config.src,
      lessonId: "",
      info: {},
      loading: true,
    };
  },
  created() {
    let _self = this;
    _self.lessonId = _self.$route.params.lessonId;
    _self.initData(_self.lessonId);
  },
  methods: {
    // 初始化
    initData(id) {
      let _self = this;
      _self.axios
        .post(config.url.lessonInfo, _self.$qs.stringify({ lesson_id: id }))
        .then((res) => {
          if (res.code == 200) {
            _self.info = res.data;
            setTimeout(() => {
              _self.loading = false;
            }, 150);
          } else if (res.code != 200 && res.code != 401) {
            _self.showTip("error", res.msg);
          }
        });
    },
    // 取消收藏
    handleFavorCanel(status, index, item) {
      let _self = this;
      _self.info.collect_status = status;
    },
    // 收藏
    handleFavor(status, index, item) {
      let _self = this;
      _self.info.collect_status = status;
    },
    // 发布到工作台
    handleWorkbench(status, index, item) {
      let _self = this;
      _self.info.issue_status = status;
    },
    // 取消发布到工作台
    workbenchCancel(status, index, item) {
      let _self = this;
      _self.info.issue_status = status;
    },
  },
};
</script>
<style lang="scss" scoped>
.con {
  .item {
    background: #fff;
    box-sizing: border-box;
    margin: 60px auto;
    .item-l {
      width: 310;
      height: 210px;
      flex-shrink: 0;
      flex-grow: 0;
      border-radius: $border-radius-main;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .item-r {
      width: 890px;
      height: 210px;
      flex-shrink: 0;
      flex-grow: 0;
      padding: 10px 30px 10px;
      box-sizing: border-box;
      border-left: 0;
      border-radius: 0 10px 10px 0;
      display: flex;
      flex-direction: column;
      h3 {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .desc {
        font-size: 18px;
        color: $font-color-51;
        line-height: 30px;
        height: 60px;
        margin-bottom: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; //行数
        -webkit-box-orient: vertical;
      }

      .price {
        font-size: 30px;
        color: $color-main;
        font-weight: bold;
        margin: 0 20px 0 30px;
      }
      .del-price {
        font-size: 18px;
        color: $font-color-9f;
      }
      .itme-r-b {
        background: $font-color-9f;
      }

      .icon-duihao {
        margin-right: 5px;
      }
    }
  }
  .icon-shoucang,
  .icon-star-filled {
    color: #f9e457;
    margin-right: 7px;
  }
  .favor i,
  .btn:hover,
  .list:hover {
    cursor: pointer;
  }
  .favor-work {
    color: $font-color-9f;
    justify-content: flex-start;
  }
  .preview img {
    width: 18px;
    height: 14px;
    margin: 4px 7px 0 0;
  }
  .slash {
    margin: 0 15px;
  }
  .favor {
    margin-top: -3px;
  }
  .favor i {
    margin-right: 6px;
  }
  .course-title {
    position: relative;
    margin-bottom: 80px;
    .line {
      width: 100%;
      height: 10px;
      background: #f9f9f9;
      border-radius: 5px;
    }
    .title {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -8px;
      img {
        width: 310px;
        height: 60px;
      }
      span {
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translate(-50%, -50%);
        font-size: 30px;
        color: #fff;
      }
    }
  }
}
</style>
